@import '/src/variables.less';

.public-header {
  height: @headerHeight;
  line-height: @headerHeight;
  padding: unset;

  // This should center the row if the max width is hit
  > * {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 8px; // This seems to align the logo and the content well
  }

  .ant-menu {
    background-color: @headerColor;
    display: flex;
    width: 100%;
    justify-content: flex-end;
    border: unset;

    .ant-menu-title-content > a {
      color: #545454;
      font-weight: 500;
      font-size: 16px;
    }
  }

  // Site logo stuff to make it look nice
  .site-logo-container {
    height: 64px;
    width: 64px;
    padding: 4px;
    display: flex;
    justify-content: center;
    background-color: @headerColor;
  }
  .site-logo {
    height: 100%;
    width: 100%;
  }
}

@primary-color: #187BB3;@menu-icon-size: 22px;@menu-icon-size-lg: 24px;@layout-header-background: @primary-color;@layout-header-color: #FFF;@layout-body-background: #FFF;@layout-sider-background: #0F2F4F;@menu-item-color: @primary-color;@menu-highlight-color: #FFF;@layout-header-padding: 0 16px;@font-family: -apple-system, BlinkMacSystemFont, Roboto, 'Segoe UI', 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol','Noto Color Emoji';@divider-color: rgba(0, 0, 0, 15%);@table-row-hover-bg: #EAE8E9;@table-header-sort-active-bg: #EAE8E9;