@import '/src/variables.less';
@import '~antd/lib/style/themes/default.less';

.centered-layout {
  height: 100vh;

  // Adds background to the layout
  background: linear-gradient(180deg, #36a3e2 0%, #2a68a7 100%);

  // Remove the weird border on the bottom of the menu items when hovering
  .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item:hover::after,
  .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-active::after,
  .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-open::after,
  .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-selected::after {
    content: unset !important;
  }

  // Header
  .public-header {
    // height: 100%;
    display: block;
    line-height: 64px;
    background-color: @headerColor;

    .ant-menu {
      background-color: @headerColor;
      display: flex;
      // width: 100%;
      justify-content: flex-end;
      border: unset;

      .ant-menu-title-content > a {
        color: #545454;
        font-weight: 500;
        font-size: 16px;
      }
    }
  }

  // Adds better scrolling (mostly due to the content-container and inner-container)
  .content-container {
    // Fixes the double background being on top of the content. Another very strange css thing that seems like it should not be
    position: relative;

    .inner-container {
      position: relative; // Fix for floating items, such as the button on the terms of service page
      margin: 76px auto;
      padding: 36px 40px;
      background-color: @componentBackground;
      box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.15);

      // Odd display type but it shows the margin at the bottom properly
      display: table;

      // Adds rounded corners
      border-radius: 8px;
      // Centers text since the components will be centered anyway
      text-align: center;
    }
  }
}
@primary-color: #187BB3;@menu-icon-size: 22px;@menu-icon-size-lg: 24px;@layout-header-background: @primary-color;@layout-header-color: #FFF;@layout-body-background: #FFF;@layout-sider-background: #0F2F4F;@menu-item-color: @primary-color;@menu-highlight-color: #FFF;@layout-header-padding: 0 16px;@font-family: -apple-system, BlinkMacSystemFont, Roboto, 'Segoe UI', 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol','Noto Color Emoji';@divider-color: rgba(0, 0, 0, 15%);@table-row-hover-bg: #EAE8E9;@table-header-sort-active-bg: #EAE8E9;