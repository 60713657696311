.home-header {
  // Hacking to get results, ehhh
  height: 0px;
  z-index: 10;
  position: relative;

  .ant-menu {
    background-color: transparent;
    display: flex;
    width: 100%;
    justify-content: flex-end;
    border: unset;

    .ant-menu-title-content > a {
      // color: #545454;
      color: white;
      font-weight: 500;
      font-size: 18px;
      filter: drop-shadow(2px 4px 6px black);
    }

    .ant-menu-overflow-item > div {
      display: flex;
      height: 100%;

      color: white;
      font-weight: 500;
      font-size: 18px;
      filter: drop-shadow(2px 4px 6px black);

      > .anticon {
        font-size: 30px;
      }
    }
  }
}

@primary-color: #187BB3;@menu-icon-size: 22px;@menu-icon-size-lg: 24px;@layout-header-background: @primary-color;@layout-header-color: #FFF;@layout-body-background: #FFF;@layout-sider-background: #0F2F4F;@menu-item-color: @primary-color;@menu-highlight-color: #FFF;@layout-header-padding: 0 16px;@font-family: -apple-system, BlinkMacSystemFont, Roboto, 'Segoe UI', 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol','Noto Color Emoji';@divider-color: rgba(0, 0, 0, 15%);@table-row-hover-bg: #EAE8E9;@table-header-sort-active-bg: #EAE8E9;