@import '/src/variables.less';

.public-layout {
  // background: linear-gradient(180deg, #f4f4f4 0%, #ffffff 100%);
  background-color: #ffffff;
  height: 100vh;

  // Header
  .public-header {
    height: @headerHeight;
    line-height: @headerHeight;
    background-color: @headerColor;

    .ant-menu {
      background-color: @headerColor;
      display: flex;
      width: 100%;
      justify-content: flex-end;
      border: unset;

      .ant-menu-title-content > a {
        color: #545454;
        font-weight: 500;
        font-size: 16px;
      }
    }
  }

  // testing some stuff
  .flex-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%,
  }
  .content-container {
    padding: 24px 16px;
    max-width: @contentWidth;
      width: 100%;
  }

  // Content
  // .content-container {
  //   padding: 24px 16px;
  //   max-width: @contentWidth;
  //     width: 100%;

  //   .inner-container {
  //     width: 100%;

  //     // Not 100% on this guy
  //     text-align: center;
  //   }
  // }

  // This hunk is ALL for a footer stuck to bottom OR pushed by content (inspired from: https://stackoverflow.com/a/25671210)
  // The only downside is that is not responsive. Will be for a future update, thinking some sort of flex content, with flex-grow and no flex-shrink?
  // Possible idea: https://stackoverflow.com/questions/67946290/footer-at-bottom-of-page-for-responsive-screen
  .expandable-container {
    display: table; // I have no idea what magic this accomplishes but it is the only thing that is working
    min-height: 100%;
    margin-bottom: -@standardFooterHeight;
    padding-bottom: @standardFooterHeight;
  }
}

@primary-color: #187BB3;@menu-icon-size: 22px;@menu-icon-size-lg: 24px;@layout-header-background: @primary-color;@layout-header-color: #FFF;@layout-body-background: #FFF;@layout-sider-background: #0F2F4F;@menu-item-color: @primary-color;@menu-highlight-color: #FFF;@layout-header-padding: 0 16px;@font-family: -apple-system, BlinkMacSystemFont, Roboto, 'Segoe UI', 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol','Noto Color Emoji';@divider-color: rgba(0, 0, 0, 15%);@table-row-hover-bg: #EAE8E9;@table-header-sort-active-bg: #EAE8E9;