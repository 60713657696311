@import '/src/variables.less';

.home-layout {
  background: linear-gradient(180deg, #f4f4f4 0%, #ffffff 100%);
  // background-color: #93f5fc;
  height: 100vh;

  // Content
  .content-container {
    // padding: 24px 16px;
    // max-width: @siteStandardWidthInner;
    // min-width: 400px;
    max-width: 1200px;
    margin: 0 auto;

    .header-container {
      background: url(/assets/people-holding-chat-bubbles.jpg);
      background-size: cover;
      background-repeat: no-repeat;
      // background-position: 50% 50%;
      height: 550px;
    }

    .blue-blur-container {
      // height: 400px;
      background-color: rgba(66, 139, 221, 0.7);
      backdrop-filter: blur(2px);

      * {
        color: white;
      }
    }

    // .inner-container {
    //   display: flex;
    //   flex-direction: column;
    //   align-items: center;
    //   justify-content: center;
    //   width: 100%;
    // }

    .header-image {
      // width: 100%;
      // height: 520px;
      width: 100%;
      // max-width: 800px;
      height: auto;
      display: inline-block;
      margin: 0px auto;
    }

    .quick-search-container {
      z-index: 1;
      position: relative;
      margin: -60px auto 20px; // Center the container
      padding: 16px 24px 8px;
      background-color: @componentBackground;
      box-shadow: 0px 5px 20px 10px rgba(0, 0, 0, 0.15);
      width: calc(100% - 40px);

      // Odd display type but it shows the margin at the bottom properly
      display: table;

      // Adds rounded corners
      border-radius: 8px;

      // Dropdowns could use some rounding as well
      .ant-select > .ant-select-selector, .ant-btn {
        border-radius: 6px;
      }

      .ant-form-item-label > label {
        // Color the labels
        color: #116da5;

        // Move the label juuuuust a bit
        margin-left: 4px;
      }

      // Okay, fix the form layout as it is horizontal but also vertical
      .ant-form {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        grid-gap: 12px;
      }
    }

    .our-interpreters {
      // The cover breaks the border, because of course it does
      .ant-card-cover {
        padding: 1 0
      }

      // Far too much padding
      .ant-card-body {
        text-align: center;
        padding: 12px;
      }
    }

    .join-team-image {
      width: 100%;
    }
  }

  // These are any mobile specific styles and overrides
  .mobile {
    &.content-container {
      max-width: unset;
      margin: unset;
    }

    .blue-blur-container {
      padding: 30px 18px 18px;
    }

    .header-container {
      max-height: 300px;
    }

    .quick-search-container {
      width: calc(100% - 40px);

      // Dropdown override for mobile
      .ant-select > .ant-select-selector, .ant-btn {
        width: unset;
      }

      .ant-form {
        display: unset;
      }
    }
  }

  // This hunk is ALL for a footer stuck to bottom OR pushed by content (inspired from: https://stackoverflow.com/a/25671210)
  // The only downside is that is not responsive. Will be for a future update, thinking some sort of flex content, with flex-grow and no flex-shrink?
  // Possible idea: https://stackoverflow.com/questions/67946290/footer-at-bottom-of-page-for-responsive-screen
  .expandable-container {
    // Dev Note: These are only needed when the page is not full height, which the home page definitely is now
    // display: table; // I have no idea what magic this accomplishes but it is the only thing that is working
    // min-height: 100%;
    margin-bottom: -@standardFooterHeight;
    padding-bottom: @standardFooterHeight;
    // max-width: 1200px;
    // margin: 0 auto;
  }
}
@primary-color: #187BB3;@menu-icon-size: 22px;@menu-icon-size-lg: 24px;@layout-header-background: @primary-color;@layout-header-color: #FFF;@layout-body-background: #FFF;@layout-sider-background: #0F2F4F;@menu-item-color: @primary-color;@menu-highlight-color: #FFF;@layout-header-padding: 0 16px;@font-family: -apple-system, BlinkMacSystemFont, Roboto, 'Segoe UI', 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol','Noto Color Emoji';@divider-color: rgba(0, 0, 0, 15%);@table-row-hover-bg: #EAE8E9;@table-header-sort-active-bg: #EAE8E9;