@import '/src/variables.less';

@headerHeight: 64px;
@contentPadding: 20px;
@logoHeight: 32px;

// These are provided by AntD and are pulled in here for calculations
@footerHeight: 22px;
@footerPadding: 24px;


// Fixes layout to give a static header and sidenav with scrolling content
.dev-layout {
  height: 100vh;

  .layout-sider {
    .logo {
      height: @logoHeight;
      margin: 16px;
      background: rgba(255, 255, 255, 0.3);
    }

    .layout-sider-menu {
      // height: 100%;
    }
  }

  .content-layout {
    padding: 0;
    height: 100vh;
  }

  .layout-header {
    height: @headerHeight;
  }

  .content-container {
    height: 100vh;
    overflow-x: hidden;

    // Adds standard padding to the content area
      padding: @contentPadding 16px;

    // This will force the footer to be at the bottom of the content or page, which looks really nice on short pages
    > *:first-child {
      // Calculation logic: Full height minus header, then footer, then padding on footer and padding on content
      min-height: calc(100vh - @headerHeight - @footerHeight - (@footerPadding * 2) - (@contentPadding * 2));
    }
  }
}

// Idk what this is
/* SideNav */
// .ant-menu {
//   background-color: @primaryDark;

//   // Long weird class name stuff because of overrides
//   &.ant-menu-inline-collapsed > li.ant-menu-item {
//     // Add slight padding to better center the icons
//     padding-top: 4px;

//     // Change the selected color
//     &.ant-menu-item-selected {
//       background-color: @primary;
//     }
//   }
// }

@primary-color: #187BB3;@menu-icon-size: 22px;@menu-icon-size-lg: 24px;@layout-header-background: @primary-color;@layout-header-color: #FFF;@layout-body-background: #FFF;@layout-sider-background: #0F2F4F;@menu-item-color: @primary-color;@menu-highlight-color: #FFF;@layout-header-padding: 0 16px;@font-family: -apple-system, BlinkMacSystemFont, Roboto, 'Segoe UI', 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol','Noto Color Emoji';@divider-color: rgba(0, 0, 0, 15%);@table-row-hover-bg: #EAE8E9;@table-header-sort-active-bg: #EAE8E9;