@import '/src/variables.less';

.user-header {
  padding: unset;

  .header-content {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    display: flex;
  }
  .ant-menu {
    background-color: @headerColor;
    display: flex;
    width: 100%;
    justify-content: flex-end;
    border: unset;
    .ant-menu-title-content > a {
      color: #545454;
      font-weight: 500;
      font-size: 16px;
    }
  }
  .site-logo-container {
    height: 64px;
    width: 64px;
    padding: 4px;
    display: flex;
    justify-content: center;
    background-color: @headerColor;
  }
  .site-logo {
    height: 100%;
    width: 100%;
  }
}

@primary-color: #187BB3;@menu-icon-size: 22px;@menu-icon-size-lg: 24px;@layout-header-background: @primary-color;@layout-header-color: #FFF;@layout-body-background: #FFF;@layout-sider-background: #0F2F4F;@menu-item-color: @primary-color;@menu-highlight-color: #FFF;@layout-header-padding: 0 16px;@font-family: -apple-system, BlinkMacSystemFont, Roboto, 'Segoe UI', 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol','Noto Color Emoji';@divider-color: rgba(0, 0, 0, 15%);@table-row-hover-bg: #EAE8E9;@table-header-sort-active-bg: #EAE8E9;