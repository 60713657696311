@import '/src/variables.less';

// Variables first! (Note, order is mostly whatever, though I tend to like the 'root' at the beginning of a block
@standardSize: 16px;
@standardSizeSmall: 8px;
@standardSizeLarge: 32px;
@standardSizeVeryLarge: 64px;

@standardFontSize: 14px;
@standardFontSizeSmall: 12px;
@standardFontSizeLarge: 18px;
@standardFontSizeVeryLarge: 24px;


/* Margin */
.with-margin {
  margin: @standardSize @standardSizeSmall;

  &-large { margin: @standardSizeLarge @standardSize; }
  &-very-large { margin: @standardSizeVeryLarge @standardSizeLarge; }

  &-top {
    margin-top: @standardSize;

    &-large { margin-top: @standardSizeLarge; }
    &-very-large { margin-top: @standardSizeVeryLarge; }
  }
  &-bottom {
    margin-bottom: @standardSize;

    &-large { margin-bottom: @standardSizeLarge; }
    &-very-large { margin-bottom: @standardSizeVeryLarge; }
  }
  &-left {
    margin-left: @standardSizeSmall;

    &-large { margin-left: @standardSize; }
    &-very-large { margin-left: @standardSizeLarge; }
  }
  &-right {
    margin-right: @standardSizeSmall;

    &-large { margin-right: @standardSize; }
    &-very-large { margin-right: @standardSizeLarge; }
  }
  &-x, &-horizontal {
    margin-left: @standardSizeSmall;
    margin-right: @standardSizeSmall;

    &-large { margin-left: @standardSize;  margin-right: @standardSize; }
    &-very-large { margin-left: @standardSizeLarge;  margin-right: @standardSizeLarge; }
  }
  &-y, &-vertical {
    margin-top: @standardSizeSmall;
    margin-bottom: @standardSizeSmall;

    &-large { margin-top: @standardSize;  margin-bottom: @standardSize; }
    &-very-large { margin-top: @standardSizeLarge;  margin-bottom: @standardSizeLarge; }
  }
}

.without-margin {
  margin: unset;

  &-important { margin: unset !important; }

  &-top {
    margin-top: unset;

    &-important { margin-top: unset !important; }
  }
  &-bottom {
    margin-bottom: unset;

    &-important { margin-bottom: unset !important; }
  }
  &-left {
    margin-left: unset;

    &-important { margin-left: unset !important; }
  }
  &-right {
    margin-right: unset;

    &-important { margin-right: unset !important; }
  }
}

/* Padding */
.with-padding {
  padding: @standardSize @standardSize;

  &-small { padding: @standardSizeSmall; }
  &-large { padding: @standardSizeLarge; }
  &-very-large { padding: @standardSizeVeryLarge; }

  &-top {
    padding-top: @standardSize;

    &-small { padding-top: @standardSizeSmall; }
    &-large { padding-top: @standardSizeLarge; }
    &-very-large { padding-top: @standardSizeVeryLarge; }
  }
  &-bottom {
    padding-bottom: @standardSize;

    &-small { padding-bottom: @standardSizeSmall; }
    &-large { padding-bottom: @standardSizeLarge; }
    &-very-large { padding-bottom: @standardSizeVeryLarge; }
  }
  &-left {
    padding-left: @standardSize;

    &-small { padding-left: @standardSizeSmall; }
    &-large { padding-left: @standardSizeLarge; }
    &-very-large { padding-left: @standardSizeVeryLarge; }
  }
  &-right {
    padding-right: @standardSize;

    &-small { padding-right: @standardSizeSmall; }
    &-large { padding-right: @standardSizeLarge; }
    &-very-large { padding-right: @standardSizeVeryLarge; }
  }
}

.without-padding {
  padding: unset;

  &-important { padding: unset !important; }

  &-top {
    padding-top: unset;

    &-important { padding-top: unset !important; }
  }
  &-bottom {
    padding-bottom: unset;

    &-important { padding-bottom: unset !important; }
  }
  &-left {
    padding-left: unset;

    &-important { padding-left: unset !important; }
  }
  &-right {
    padding-right: unset;

    &-important { padding-right: unset !important; }
  }
}

/* Text adjustments (color, style, size, etc) */
.with-text {
  &-color {
    &-green { color: @success; }
    &-red { color: @danger; }
    // &-light-grey { color: @danger; }
    &-dark-grey { color: @decentlyDark; }
  }

  &-style {
    &-bold { font-weight: 700 ;}
    &-bolder { font-weight: 800 ;}
  }

  &-size {
    &-medium { font-size: @standardFontSize; }
    &-small { font-size: @standardFontSizeSmall; }
    &-large { font-size: @standardFontSizeLarge; }
    &-very-large { font-size: @standardFontSizeVeryLarge; }
  }

  &-align {
    &-left { text-align: left; }
    &-right { text-align: right; }
    &-center { text-align: center; }
    &-justify { text-align: justify; }
  }

  &-uppercase {
    text-transform: uppercase;
  }
  &-lowercase {
    text-transform: lowercase;
  }
}

// Idk what I am doing anymore
.with-special {
  &-paragraph {
    line-height: 28px;
    font-size: 16px;
  }
}

// And that is where I stopped condensing things haha...


// Backgrounds
.with-background-light-grey {
  background-color: @lightGrey;
}

// Misc

// A grid container but all the items have the same width
.with-grid-layout-equal {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
}

.with-grid-layout-half {
  display: grid;
  grid-template-columns: 50% 50%;

  grid-auto-flow: column;
  // grid-auto-columns: 1fr;
}

// Adds standard flex container things
.with-flex-layout {
  display: flex;
  flex-direction: column;
}

// Adds a white background to the given icon as they are transparent. Why? No idea
.standard-icon-rounded {
  background-color: @accentLight;
  border-radius: 50%;
}
